import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { LoadingPage, PageLayout } from '@goschool/components'
import { Trans, useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { GoSchool } from '@goschool/routing'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import { collection } from 'firebase/firestore'
import type { Course, CourseStudent } from '@goschool/model'
import { NotFound, typeConverter, useFirestoreResults, useFirestoreSnapshot } from '@goschool/react-firebase'
import { useCourseRef } from '@goschool/dao'
import { useParams } from 'react-router-dom'

function studentsBreadcrumbs(courseSnapshot: QueryDocumentSnapshot<Course>, title: string) {
  return [
    //coursePageRef(courseSnapshot),
    {
      title,
      href: GoSchool.courseStudents(courseSnapshot.ref)
    }
  ]
}

export function CourseStudentsPage() {
  const courseRef = useCourseRef(useParams<{
    organizationId: string
    courseId: string
  }>())

  const { t } = useTranslation()

  const courseSnapshot = useFirestoreSnapshot(courseRef)

  if (courseSnapshot==null) {
    return LoadingPage()
  }

  if (!courseSnapshot.exists()) {
    throw new NotFound()
  }


  return <PageLayout
    fullScreen={false}
    breadcrumbs={studentsBreadcrumbs(
      courseSnapshot, t('course:students.title')
    )}>
    <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="course:students.title" /></Typography>
    <CourseStudentsTable courseSnapshot={courseSnapshot} />
  </PageLayout>
}


function CourseStudentsTable({ courseSnapshot }: { courseSnapshot: QueryDocumentSnapshot<Course> }) {
  const studentsRef = useMemo(
    () =>
      collection(courseSnapshot.ref, 'students')
        .withConverter(typeConverter<CourseStudent>()),
    [courseSnapshot]
  )

  const students = useFirestoreResults(studentsRef)
  const { t } = useTranslation()


  return <TableContainer component={Paper}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('course:students.tableHeaders.name')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(students ?? []).map((student) => <StudentRow key={student.id} snapshot={student} />)}
      </TableBody>
    </Table>
  </TableContainer>
}


function StudentRow({ snapshot }: { snapshot: QueryDocumentSnapshot<CourseStudent> }) {
  const student = useMemo(() => snapshot.data(), [snapshot])

  return <TableRow>
    <TableCell>{student.name}</TableCell>
  </TableRow>
}

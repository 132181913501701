import { useChatContext } from './ChatContext'

import { Box, styled } from '@mui/material'
import { ChatMessageView } from './ChatMessageView'
import { ChatViewContextProvider } from './ChatViewContext'
import { PromptForm } from './PromptForm'
import { SamplePrompts } from './SamplePrompts'
import { useFirebaseAuth } from '@goschool/react-firebase'
import { MessageNodeContextProvider } from './MessageNodeContext'
import { useRef } from 'react'


export function ChatMessages() {
  const { chatManager } = useChatContext()
  const messagesContainerRef = useRef<HTMLDivElement>(null)

  if (chatManager.chat===null) {
    return <MessagesContainer ref={messagesContainerRef}>
      <ChatViewContextProvider>
        <SamplePrompts />
      </ChatViewContextProvider>

    </MessagesContainer>
  }

  return <MessagesContainer ref={messagesContainerRef}>
    <ChatViewContextProvider>
      <Messages key={chatManager.id ?? 'new'} />
    </ChatViewContextProvider>
  </MessagesContainer>
}

export function ChatPrompt() {
  const { mode, chatManager } = useChatContext()
  const { user } = useFirebaseAuth()
  const { chat } = chatManager

  const otherUser = chat!=null && (user==null || chat.created_by!==user.uid)

  const readonly = mode==='view' || otherUser
  if (readonly) {
    return null
  }

  return (
    <PromptFormContainer>
      <PromptForm />
    </PromptFormContainer>
  )
}

export function Messages() {
  const chatContext = useChatContext()

  const { chatManager, thread } = chatContext

  return <>
    {thread.map((node, i) => {
      const parent = i > 0 ? thread[i - 1]:{ children: [node] }
      return <MessageNodeContextProvider key={node?.message.id ?? chatManager.id} node={node} parent={parent}>
        <ChatMessageView />
      </MessageNodeContextProvider>
    })}
  </>
}

const MessagesContainer = styled(Box, {
  name: 'MessagesContainer',
  slot: 'Root'
})(({ theme }) => ({
  overflowY: 'auto', // Become scrollable vertically
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2), // Space between messages,
  flexGrow: 1
}))

const PromptFormContainer = styled(Box, { name: 'UserMessage', slot: 'Root' })(
  ({ theme }) => ({
    flexShrink: 0,
    flexGrow: 0,
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  })
)

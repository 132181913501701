import { type FormEvent, useState } from 'react'
import { useCallback } from 'react'
import {
  Button, DialogActions, DialogContent, DialogTitle, FormHelperText,
  TextField
} from '@mui/material'

import { Trans, useTranslation } from 'react-i18next'
import { useUserContext } from '@goschool/auth'
import { GoSchoolDialog } from '@goschool/mui'
import { LoadingButton } from '@mui/lab'
import { updateDoc } from 'firebase/firestore'
import { LanguageSelector } from './LanguageSelector'
import { pick } from 'lodash'
import { useCourseContext } from '@goschool/dao'

interface EditCourseFormProps {
  displayed: boolean;
  hide: () => void;
}

interface CourseFields {
  title: string;
  code: string;
  description: string;
  language_code: string;
  instructor_guidelines?: string | null;
}

interface CourseFieldErrors {
  title?: string;
  code?: string;
  description?: string;
  language_code?: string;
  instructor_guidelines?: string;
}

type submitState = 'idle' | 'pending' | 'success' | 'failed';

export function EditCourseDialog({ displayed, hide }: EditCourseFormProps) {
  const {courseSnapshot} = useCourseContext()
  const { goSchoolUser, user } = useUserContext()

  const [fields, setFields] = useState<CourseFields>(pick(
    courseSnapshot.data(), ['title', 'code', 'description', 'language_code', 'instructor_guidelines']
  ))
  const [fieldErrors, setFieldErrors] = useState<CourseFieldErrors>({})
  const [error, setError] = useState<string | null>(null)
  const [state, setState] = useState<submitState>('idle')

  const setField = useCallback((key: keyof CourseFields, value: string) => {
    setFields(fields => ({ ...fields, [key]: value }))
  }, [])

  const { t } = useTranslation()

  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (state!=='idle') {
        return
      }

      if (goSchoolUser?.organization==null || user==null) {
        return
      }
      setState('pending')
      setError(null)
      setFieldErrors({})

      try {
        await updateDoc(courseSnapshot.ref, {
          title: fields.title,
          code: fields.code,
          language_code: fields.language_code,
          description: fields.description,
          instructor_guidelines: fields.instructor_guidelines
        })
        setState('success')
        hide()
        setState('idle')
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError('An unknown error occurred')
        }
        setState('failed')
      }
    }, [state, goSchoolUser, user, courseSnapshot, fields, hide]
  )

  const cancel = useCallback(
    () => hide(),
    [hide]
  )

  return <GoSchoolDialog open={displayed} maxWidth="md" fullWidth={true} onClose={hide}>
    <form onSubmit={submit}>

      <DialogTitle><Trans i18nKey="course:forms.edit.title" /></DialogTitle>
      <DialogContent>
        <TextField
          size="small" fullWidth={true} margin="normal"
          label={t('course:forms.edit.fields.title')} value={fields.title ?? ''}
          error={fieldErrors.title!=null} helperText={fieldErrors.title}
          onChange={e => setField('title', e.target.value)} />

        <TextField
          size="small" fullWidth={true} margin="normal"
          label={t('course:forms.edit.fields.code')} value={fields.code ?? ''}
          error={fieldErrors.code!=null} helperText={fieldErrors.code}
          onChange={e => setField('code', e.target.value)} />

        <LanguageSelector languageCode={fields.language_code} setLanguageCode={
          (language) => setField('language_code', language)
        } label={t('course:forms.edit.fields.language')} />


        <TextField
          size="small" fullWidth={true} multiline={true} margin="normal"
          rows={3}
          error={fieldErrors.description!=null} helperText={fieldErrors.description}
          label={t('course:forms.edit.fields.description')} value={fields.description ?? ''}
          onChange={e => setField('description', e.target.value)} />

        <TextField
          size="small" fullWidth={true} multiline={true} margin="normal"
          rows={5}
          error={fieldErrors.instructor_guidelines!=null} helperText={fieldErrors.instructor_guidelines}
          label={t('course:forms.edit.fields.instructorGuidelines')} value={fields.instructor_guidelines ?? ''}
          onChange={e => setField('instructor_guidelines', e.target.value)} />

        {error!=null && <FormHelperText error={true}>{error}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <LoadingButton sx={{ flexShrink: 0 }} type="submit" variant="contained" color="primary"
                       loading={state==='pending'}
                       disabled={['success', 'pending'].includes(state) || goSchoolUser?.organization==null ||
                         (fields.title ?? '').trim()==='' ||
                         (fields.code ?? '').trim()==='' ||
                         (fields.description ?? '').trim()===''
                       }
                       autoFocus={true} onClick={submit}>
          <Trans i18nKey="course:forms.edit.submit" />
        </LoadingButton>
        <Button variant="outlined" sx={{ textTransform: 'inherit' }} onClick={cancel} size="small"><Trans
          i18nKey="course:forms.edit.cancel" /></Button>
      </DialogActions>
    </form>
  </GoSchoolDialog>
}




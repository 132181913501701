import type { ChatMessage} from '@goschool/model'
import type { PropsWithChildren} from 'react'
import { useMemo } from 'react'
import { useContext, createContext } from 'react'
import type { MessageNode, MessageTree } from '../model/MessageTree'




interface MessageNodeContextProviderProps {
  node: MessageNode
  parent: MessageNode | MessageTree
}

interface MessageNodeContextType extends MessageNodeContextProviderProps {
  message: ChatMessage
}

const MessageNodeContext = createContext<MessageNodeContextType | undefined>(undefined)

export function MessageNodeContextProvider({ children, node, parent }: PropsWithChildren<MessageNodeContextProviderProps>) {
  const message = useMemo(
    () => node.message.data(),
    [node]
  )
  return <MessageNodeContext.Provider value={{ node: node, parent, message }}>{children}</MessageNodeContext.Provider>
}

export function useMessageNodeContext() {
  const context = useContext(MessageNodeContext)
  if (context==null) {
    throw new Error('useMessageTreeContext must be used within a MessageTreeContextProvider')
  }
  return context
}

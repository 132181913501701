import type { ChatMessage } from '@goschool/model'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import { maxBy, sortBy } from 'lodash'


export interface MessageTree {
  children: MessageNode[];
}

export interface MessageNode {
  parent?: MessageNode;
  message: QueryDocumentSnapshot<ChatMessage>;
  children: MessageNode[];
}

export type MessageMap = Record<string, MessageNode>;

export function collectThread(
  node: MessageNode | null,
  acc: MessageNode[] = []
): MessageNode[] {
  if (node==null) {
    return acc
  }
  return collectThread(node.parent ?? null, [node, ...acc])
}

export function findLatestLeaf(node: MessageNode): MessageNode {
  if (node.children.length===0) {
    return node
  }

  const leafs = node.children.map((leaf) => findLatestLeaf(leaf))
  const latest = maxBy(leafs, (leaf) => leaf.message.data().created_at)

  if (latest==null) {
    throw new Error('No latest leaf found')
  }

  return latest
}

export function buildTree(messageMap: MessageMap): MessageTree {
  const tree: MessageTree = { children: [] }

  const nodes = Object.values(messageMap)

  nodes.forEach(({ message }) => {
    const data = message.data()
    const node = messageMap[message.id]

    if (node==null) {
      throw new Error(`Node not found for message id ${message.id}`)
    }

    const parentId = data.parent
    if (parentId==null) {
      tree.children.push(node)
    } else {
      const parentNode = messageMap[parentId]
      if (parentNode==null) {
        throw new Error(`Parent node not found for message id ${parentId}`)
      }
      node.parent = parentNode
      parentNode.children.push(node)
    }
  })

  if (tree.children.length===0) {
    throw new Error('No roots found')
  }

  return { children: tree.children.map(sortChildren) }
}

function sortChildren(tree: MessageNode): MessageNode {
  return {
    ...tree,
    children: sortBy(tree.children, (child) => child.message.data().created_at)
  }
}

export function generateMessageMap(messages: QueryDocumentSnapshot<ChatMessage>[]): MessageMap {
  const messageMap: MessageMap = {}

  messages.forEach((message) => {
    messageMap[message.id] = {
      message,
      children: []
    }
  })

  return messageMap
}

import { Typography } from '@mui/material'
import { PageLayout } from '@goschool/components'
import { useParams } from 'react-router-dom'

import type { QueryDocumentSnapshot } from 'firebase/firestore'
import type { Course, CourseChat } from '@goschool/model'
import { useUserContext } from '@goschool/auth'

import { ChatContextProvider, ChatMessages } from '@goschool/shared/chat'
import { useFirestoreSnapshot } from '@goschool/react-firebase'
import { GoSchool } from '@goschool/routing'
import { useCourseChatRef, useCourseRef } from '@goschool/dao'
import { useChatManager } from './components/useChatManager'
import { useMemo } from 'react'


export function CourseChatViewPage() {
  const courseChatRef = useCourseChatRef(useParams<{
    organizationId: string
    courseId: string
    chatId: string
  }>())

  const courseRef = useCourseRef(useParams<{
    organizationId: string
    courseId: string
  }>())

  const courseSnapshot = useFirestoreSnapshot(courseRef)
  const courseChatSnapshot = useFirestoreSnapshot(courseChatRef)

  if (courseSnapshot==null || courseRef==null || !courseSnapshot.exists()) {
    return null
  }

  if (courseChatSnapshot==null || courseChatRef==null || !courseChatSnapshot.exists()) {
    return null
  }

  return <CourseChatViewLayout courseSnapshot={courseSnapshot} courseChatSnapshot={courseChatSnapshot} />
}

function courseChatViewBreadcrumbs(
  courseSnapshot: QueryDocumentSnapshot<Course>,
  selectedChat: QueryDocumentSnapshot<CourseChat>) {
  return [
    //coursePageRef(courseSnapshot),
    {
      title: selectedChat.data().title ?? 'New Chat',
      href: GoSchool.courseChatView(selectedChat.ref)
    }
  ]
}

function CourseChatViewLayout({ courseSnapshot, courseChatSnapshot }: {
  courseSnapshot: QueryDocumentSnapshot<Course>,
  courseChatSnapshot: QueryDocumentSnapshot<CourseChat>
}) {
  const { user } = useUserContext()
  const chatRef = useMemo(() => courseChatSnapshot.ref, [courseChatSnapshot])

  const chatManager = useChatManager(user, chatRef)

  return <ChatContextProvider chatManager={chatManager} mode="view">
    <PageLayout
      fullScreen={false}
      breadcrumbs={courseChatViewBreadcrumbs(courseSnapshot, courseChatSnapshot)}>
      <Typography variant="h4" component="h1" gutterBottom={true}>{courseChatSnapshot.data().title ?? 'New Chat'}</Typography>
      <ChatMessages />
    </PageLayout>
  </ChatContextProvider>
}

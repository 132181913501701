import type { User, Auth } from 'firebase/auth'
import type { PropsWithChildren} from 'react'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { typeConverter, useFirebaseAuth, useFirestore, useFirestoreSnapshot } from '@goschool/react-firebase'
import type { GoSchoolInvitation, GoSchoolUser, GoSchoolUserRole, Organization } from '@goschool/model'
import { useGoSchoolFunctions } from '@goschool/model'
import type { DocumentReference } from 'firebase/firestore'
import { doc, updateDoc } from 'firebase/firestore'



export interface UserContextType {
  user?: User | null;
  state: 'pending' | 'success' | 'error';
  auth: Auth;
  roles: GoSchoolUserRole[];
  goSchoolUser: GoSchoolUser | undefined | null
  acceptInvitation: (invitationRef: DocumentReference<GoSchoolInvitation>) => Promise<void>
  selectOrganization: (ref: DocumentReference<Organization>) => Promise<void>
}


export const UserContext =
  createContext<UserContextType | undefined>(undefined)


export function UserContextProvider({ children }: PropsWithChildren) {
  const firebaseAuth = useFirebaseAuth()
  const [roles, setRoles] = useState<GoSchoolUserRole[]>([])

  const { acceptInvitation: invokeAcceptInvitation } = useGoSchoolFunctions()

  const updateRoles = useCallback(
    async (user: User, forceRefresh = false) => {
      const tokenResult = await user.getIdTokenResult(forceRefresh)
      if ('roles' in tokenResult.claims && Array.isArray(tokenResult.claims.roles)) {
        const roles = tokenResult.claims.roles.filter(isGoSchoolUserRole)
        setRoles(roles)
      }
    }, []
  )

  useEffect(() => {
    const { user } = firebaseAuth
    if (user!=null) {
      updateRoles(user)
    }
  }, [firebaseAuth, updateRoles])

  const acceptInvitation = useCallback(
    async (invitationRef: DocumentReference<GoSchoolInvitation>) => {
      await invokeAcceptInvitation(invitationRef)
      const { user } = firebaseAuth
      if (user!=null) {
        updateRoles(user, true)
      }
    }, [firebaseAuth, invokeAcceptInvitation, updateRoles]
  )

  const firestore = useFirestore()

  const userRef = useMemo(
    () => {
      const user = firebaseAuth.user
      if (user!=null) {
        return doc(firestore, 'users', user.uid).withConverter(typeConverter<GoSchoolUser>())
      }
    }, [firebaseAuth.user, firestore]
  )

  const userSnapshot = useFirestoreSnapshot(userRef, { waitForWrites: true })

  const selectOrganization = useCallback(
    async (ref: DocumentReference<Organization>) => {
      if (userRef==null) {
        return
      }
      updateDoc(userRef, { organization: ref })
    }, [userRef]
  )

  const goSchoolUser = useMemo(
    () => {
      return userSnapshot===undefined
        ? undefined
        :userSnapshot?.data() ?? null
    },
    [userSnapshot]
  )

  return <UserContext.Provider value={{ ...firebaseAuth, selectOrganization, roles, goSchoolUser, acceptInvitation }}>{children}</UserContext.Provider>
}

export function useUserContext() {
  const context = useContext(UserContext)
  if (context==null) {
    throw new Error('useAuthContext must be used within an AuthContextProvider')
  }
  return context
}



function isGoSchoolUserRole(role: string): role is GoSchoolUserRole {
  return ['admin', 'instructor', 'student'].includes(role)
}

import type { Course } from '@goschool/model'
import { GoSchool } from '@goschool/routing'
import ChatIcon from '@mui/icons-material/Chat'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { Grid2 as Grid, Stack } from '@mui/material'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CourseActionLink, CourseDescription, InstructorActionLink } from './components'

export function StudentCourse({ snapshot }: { snapshot: QueryDocumentSnapshot<Course> }) {
  const [course, ref] = useMemo(
    () => [snapshot===null ? null:snapshot?.data(), snapshot?.ref],
    [snapshot]
  )
  const { t } = useTranslation()

  return <Stack>
    <Stack direction="row" gap={2} justifyContent="stretch">
      <Stack direction="row" gap={1} justifyContent="flex-start" flexGrow={1}>
        <InstructorActionLink variant="contained" label={t('course:actions.chat')}
                              to={GoSchool.courseChat(snapshot.ref)}
                              Icon={ChatIcon}></InstructorActionLink>
        <InstructorActionLink variant="outlined" label={t('course:actions.studentChats')}
                              to={GoSchool.courseChats(ref)}
                              Icon={ForumOutlinedIcon} />
      </Stack>

      <Stack direction="row" gap={1} justifyContent="flex-end" flexGrow={1}>
        <CourseActionLink variant="outlined" label={t('course:actions.documents')}
                          to={GoSchool.courseDocuments(ref)}
                          Icon={MenuBookIcon} />
        <InstructorActionLink variant="outlined" label={t('course:actions.students')}
                              to={GoSchool.courseStudents(ref)}
                              Icon={GroupsIcon} />
      </Stack>
    </Stack>
    <CourseDescription>{course?.description}</CourseDescription>
  </Stack>
}



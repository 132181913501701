import { useUserContext, useAuthenticationContext } from '@goschool/auth'
import type { PropsWithChildren, ReactNode } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { LoadingPage, PageLayout } from '@goschool/components'
import { Avatar, Container, Stack, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { doc } from 'firebase/firestore'
import type { GoSchoolInvitation } from '@goschool/model'
import {
  NotFound,
  typeConverter,
  useFirebaseAnalytics,
  useFirestore,
  useFirestoreSnapshot, useFirestoreSnapshotWithError
} from '@goschool/react-firebase'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import type { User } from 'firebase/auth'
import { Routing } from '@goschool/routing'
import { logEvent } from 'firebase/analytics'
import { FirebaseError } from 'firebase/app'
import { LoadingButton } from '@mui/lab'

export function InvitationPage() {
  const { signUp } = useAuthenticationContext()
  const { roles, user, state } = useUserContext()
  const { invitationId } = useParams<{ invitationId: string }>()

  if (invitationId==null) {
    throw new NotFound()
  }

  const { analytics } = useFirebaseAnalytics()
  useEffect(() => {
    if (user!=null) {
      logEvent(analytics, 'invitation_page_opened', {
        invitation_id: invitationId
      })
    }
  }, [analytics, invitationId, user])

  useEffect(
    () => {
      if (user===null) {
        signUp()
      }
    },
    [state, user, roles, signUp]
  )

  if (user==null) {
    return <LoadingPage />
  }


  return <AcceptInvitation invitationId={invitationId} user={user} />
}

function AcceptInvitation({ invitationId, user }: { invitationId: string, user: User }) {
  const { t } = useTranslation()
  const invitationRef = useInvitationRef(invitationId)
  const navigate = useNavigate()
  const { acceptInvitation } = useUserContext()

  console.log('invitationRef', invitationRef.path)
  console.dir(user)
  const invitationSnapshot = useFirestoreSnapshot(invitationRef)
  const invitation: GoSchoolInvitation | undefined = useMemo(
    () => invitationSnapshot?.data(),
    [invitationSnapshot]
  )

  const [organizationSnapshot] = useFirestoreSnapshotWithError(invitation?.organization)
  const [courseSnapshot] = useFirestoreSnapshotWithError(invitation?.course)
  const expired = useMemo(() => {
    if (invitation?.expires_at==null) {
      return false
    }

    return invitation.expires_at.toDate().getTime() < Date.now()
  }, [invitation])

  const [errorCode, setErrorCode] = useState<string>()
  const [acceptState, setAcceptState] = useState<'idle' | 'working'>('idle')

  const { analytics } = useFirebaseAnalytics()
  const accept = useCallback(
    async () => {
      try {
        setAcceptState('working')
        setErrorCode(undefined)
        await acceptInvitation(invitationRef)
        logEvent(analytics, 'invitation_accepted', {
          invitation_id: invitationId
        })
        navigate(Routing.home)
      } catch (error) {
        if (error instanceof FirebaseError) {
          if (error.message==='invitation/already-accepted') {
            navigate(Routing.home)
          } else {
            setErrorCode(error.message)
          }
        } else {
          setErrorCode('invitation/unknown')
        }
      } finally {
        setAcceptState('idle')
      }
    },
    [acceptInvitation, analytics, invitationId, invitationRef, navigate]
  )

  if (invitation===undefined) {
    return null
  }

  if (invitation===null || (invitation.email!=null && user.email?.toLowerCase()!==invitation.email.toLowerCase())) {
    return <WarningPage icon={<HelpOutlineIcon color="warning" />} title={t('auth:invitation.warnings.notFound.title')}>
      <Trans i18nKey="auth:invitation.warnings.notFound.description" />
    </WarningPage>
  }

  if (expired) {
    return <WarningPage icon={<HelpOutlineIcon color="warning" />} title={t('auth:invitation.warnings.expired.title')}>
      <Trans i18nKey="auth:invitation.warnings.expired.description" />
    </WarningPage>
  }

  return <PageLayout fullScreen={true} centered={true}>
    <Container maxWidth="md">
      <Typography variant="h3" component="h1" gutterBottom={true}><Trans
        i18nKey="auth:invitation.accept.title" /></Typography>
      {
        organizationSnapshot!=null && organizationSnapshot.exists() &&  <Typography variant="h4" component="h2" gutterBottom={true}>
          <Trans i18nKey="auth:invitation.accept.organization" values={{organization:organizationSnapshot.data().name}} />
        </Typography>
      }
      {
        courseSnapshot!=null && courseSnapshot.exists() &&  <Typography variant="h4" component="h2" gutterBottom={true}>
          <Trans i18nKey="auth:invitation.accept.course" values={{organization:courseSnapshot.data().title}} />
        </Typography>
      }
      <Typography variant="body1" gutterBottom={true}><Trans
        i18nKey="auth:invitation.accept.description" /></Typography>
      <LoadingButton loading={acceptState==='working'} variant="contained" onClick={accept}><Trans
        i18nKey="auth:invitation.accept.button" /></LoadingButton>
      {errorCode &&
        <Typography variant="body1" color="error">{t(`auth:invitation.accept.errors.${errorCode}`)}</Typography>}
    </Container>
  </PageLayout>
}

function useInvitationRef(invitationId: string) {
  const firestore = useFirestore()
  return useMemo(
    () => doc(firestore, 'invitations', invitationId).withConverter(typeConverter<GoSchoolInvitation>()),
    [firestore, invitationId]
  )
}

function WarningPage({ icon, title, children }: PropsWithChildren<{ icon: ReactNode, title: string }>) {
  return <PageLayout fullScreen={true} centered={true}>
    <Container maxWidth="md">
      <Stack direction="row" gap={2} alignItems="center" justifyContent="stretch">
        <Avatar>{icon}</Avatar>
        <Typography variant="h3" component="h1">
          {title}
        </Typography>
      </Stack>
      <Typography variant="h5" component="p">{children}</Typography>
    </Container>
  </PageLayout>
}

import { Authorize, useUserContext } from '@goschool/auth'
import { type PropsWithChildren, type ReactNode, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FixedFab, LoadingPage, PageLayout } from '@goschool/components'
import { Fab, Link, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import {
  typeConverter,
  useFirestore,
  useFirestoreResults,
  useFirestoreSnapshot
} from '@goschool/react-firebase'
import type { User } from 'firebase/auth'
import { and, collection, doc, or, orderBy, query, Timestamp, where } from 'firebase/firestore'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import type { GoSchoolInvitation, GoSchoolUser } from '@goschool/model'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import ShareIcon from '@mui/icons-material/Share'
import { TransRelTime } from '@goschool/i18n'
import { CreateInvitationDialog } from './InvitationDialog'
import { GoSchool } from '@goschool/routing'

export function InvitationsPage() {
  const { roles, user } = useUserContext()
  const [creating, setCreating] = useState(false)
  const isAdmin = useMemo(() => roles.includes('admin'), [roles])

  const { invitations } = useInvitations(user, isAdmin)
  const { t } = useTranslation()

  if (invitations==null) {
    return <LoadingPage />
  }


  if ((invitations ?? []).length===0) {
    if (roles.includes('instructor') || roles.includes('admin')) {
      return <>
        <WarningPage icon={<ShareIcon />} title={t('auth:invitations.warnings.noInvitations.title')}>
          <>
            <Trans i18nKey="auth:invitations.warnings.noInvitations.description" />
            <Fab color="primary" sx={{ marginLeft: '1rem' }} onClick={() => setCreating(true)}><AddIcon /></Fab>
          </>
        </WarningPage>
        {/*<CreateCourseDialog displayed={creating} hide={() => setCreating(false)} />*/}
      </>
    } else {
      return <WarningPage icon={<MeetingRoomIcon />} title={t('auth:invitations.warnings.noInvitationsGetOne.title')}>
        <Trans i18nKey="auth:invitations.warnings.noInvitationsGetOne.description" />
      </WarningPage>
    }
  }

  return <PageLayout fullScreen={false}>
    <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="auth:invitations.title" /></Typography>

    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('auth:invitations.tableHeaders.issuer')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.name')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.organization')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.email')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.course')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.accepted')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.created_at')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.expires_at')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.roles')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(invitations ?? []).map((invitation) => <InvitationRow key={invitation.id} snapshot={invitation} />)}
        </TableBody>
      </Table>
    </TableContainer>
    <Authorize roles={['admin']}>
      <FixedFab onClick={() => setCreating(true)} color="primary" horizontal="right" vertical="bottom">
        <AddIcon />
      </FixedFab>
    </Authorize>
    <CreateInvitationDialog displayed={creating} hide={() => setCreating(false)} />
  </PageLayout>
}


function InvitationRow({ snapshot }: { snapshot: QueryDocumentSnapshot<GoSchoolInvitation> }) {
  const invitation = useMemo(() => snapshot.data(), [snapshot])
  const courseRef = useMemo(
    () => invitation.course,
    [invitation]
  )
  const course = useFirestoreSnapshot(courseRef)
  const acceptedCollection = useMemo(
    () => collection(snapshot.ref, 'acceptance'),
    [snapshot]
  )
  const organizationRef = useMemo(
    () => invitation.organization,
    [invitation]
  )
  const organization = useFirestoreSnapshot(organizationRef)
  const firestore = useFirestore()
  const issuedByRef = useMemo(
    () => doc(firestore, 'users', invitation.created_by)
      .withConverter(typeConverter<GoSchoolUser>()),
    [firestore, invitation]
  )

  const issuedBy = useFirestoreSnapshot(issuedByRef)

  const accepted = useFirestoreResults(acceptedCollection)

  return <TableRow>
    <TableCell>{issuedBy?.data()?.name}</TableCell>
    <TableCell><Link
      href={`${window.location.origin}${GoSchool.invitation(snapshot.ref)}`}>{invitation.name}</Link></TableCell>
    <TableCell>{organization?.data()?.name}</TableCell>
    <TableCell>{invitation.email}</TableCell>
    <TableCell>{course?.data()?.title}</TableCell>
    <TableCell>{invitation.name!=null
      ? (accepted?.length ?? 0) > 0 ? '✓':null
      :accepted?.length ?? null}</TableCell>
    <TableCell><TransRelTime ts={invitation.created_at} /></TableCell>
    <TableCell><TransRelTime ts={invitation.expires_at} /></TableCell>
    <TableCell>{invitation.roles.join(', ')}</TableCell>
  </TableRow>

}

function useInvitations(user: User | undefined | null, isAdmin: boolean) {
  const firestore = useFirestore()
  const invitationsCollection = useMemo(
    () => {
      return collection(firestore, 'invitations').withConverter(typeConverter<GoSchoolInvitation>())
    }, [firestore]
  )

  const invitationsFilter = useMemo(
    () => {
      if (user===null) {
        return null
      }
      if (user===undefined) {
        return undefined
      }

      if (isAdmin) {
        return query(
          invitationsCollection,
          or(
            where('expires_at', '==', null),
            where('expires_at', '>', Timestamp.now())
          ),
          orderBy('created_at', 'desc')
        )
      } else {
        return query(
          invitationsCollection,
          and(
            where('created_by', '==', user.uid),
            or(
              where('expires_at', '==', null),
              where('expires_at', '>', Timestamp.now())
            )
          )
        )
      }
    }, [invitationsCollection, isAdmin, user]
  )

  const invitations = useFirestoreResults(invitationsFilter)
  return { invitationsCollection, invitations }
}


function WarningPage({ icon, title, children }: PropsWithChildren<{ icon: ReactNode, title: string }>) {
  return <PageLayout fullScreen={true} centered={true}>
    <Stack direction="row" gap={2} alignItems="center" justifyContent="stretch">
      {/*<WarningAvatar>{icon}</WarningAvatar>*/}
      <Typography variant="h3" component="h1">
        {title}
      </Typography>
    </Stack>
    <Typography variant="h5" component="p">{children}</Typography>
  </PageLayout>
}

import type { FirebaseAppContextProviderProps } from './FirebaseAppContext'
import { FirebaseAppContextProvider } from './FirebaseAppContext'
import { FirebaseAuthContextProvider } from './FirebaseAuthContext'
import { FirebaseAnalyticsContextProvider } from './FirebaseAnalyticsContext'

export function FirebaseContextProvider({
  options,
  children,
}: FirebaseAppContextProviderProps) {
  return (
    <FirebaseAppContextProvider options={options}>
      <FirebaseAuthContextProvider>
        <FirebaseAnalyticsContextProvider>
          {children}
        </FirebaseAnalyticsContextProvider>
      </FirebaseAuthContextProvider>
    </FirebaseAppContextProvider>
  )
}

import { typeConverter, useFirestore } from '@goschool/react-firebase'
import { Trans, useTranslation } from 'react-i18next'
import React, { useCallback, useMemo, useState } from 'react'
import type { Timestamp } from 'firebase/firestore'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { Stack, styled, Typography, TextField, Button, FormHelperText } from '@mui/material'
import { Section } from './Scroller'


interface DemoRequest {
  name: string;
  email: string;
  organization: string;
  message: string;
  created_at?: Timestamp | null;
}

interface DemoRequestErrors {
  name: string | null;
  email: string | null;
  organization: string | null;
  message: string | null;
}

export function DemoRequestForm() {
  const { t } = useTranslation()
  const firestore = useFirestore()

  const requests = useMemo(
    () =>
      collection(firestore, 'demo-requests')
        .withConverter(typeConverter<DemoRequest>()),
    [firestore]
  )

  const [demoRequest, setDemoRequest] = useState<DemoRequest>({
    name: '', email: '', organization: '', message: ''
  })

  const [errors, setErrors] = useState<DemoRequestErrors>({
    name: null, email: null, organization: null, message: null
  })

  const [success, setSuccess] = useState<boolean>(false)

  const update = useCallback(
    (key: keyof DemoRequest) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setDemoRequest((data) => ({
          ...data,
          [key]: event.target.value
        }))
      }, [])

  const submitApplication = useCallback(
    () => {
      let valid = true
      const errors: DemoRequestErrors = {
        name: null,
        email: null,
        organization: null,
        message: null
      }
      if (demoRequest.name==='') {
        errors.name = t('landing:demoRequest.form.errors.name')
        valid = false
      }


      const email = 'example@email.com'
      if (isValidEmail(email)) {
        console.log('Valid email address')
      } else {
        console.log('Invalid email address')
      }
      if (demoRequest.email==='' || !isValidEmail(demoRequest.email)) {
        errors.email = t('landing:demoRequest.form.errors.email')
        valid = false
      }

      if (demoRequest.organization==='') {
        errors.organization = t('landing:demoRequest.form.errors.organization')
        valid = false
      }

      if (demoRequest.message==='') {
        errors.message = t('landing:demoRequest.form.errors.message')
        valid = false
      }

      setErrors(errors)
      if (valid) {
        addDoc(requests, {
          ...demoRequest,
          created_at: serverTimestamp()
        }).then(
          () => setSuccess(true)
        )
      } else {
        setSuccess(false)
      }
    }, [demoRequest, requests, t])

  return <Section>
    <Stack direction="column" alignItems="flex-end" justifyContent="stretch" flexGrow={1}>
      <ApplicationForm>
        <Typography variant="h2"><Trans i18nKey="landing:demoRequest.title" /></Typography>
        <Typography variant="body1" mb={2}><Trans i18nKey="landing:demoRequest.content" /></Typography>
        <FormHelperText><Trans i18nKey="landing:demoRequest.info" /></FormHelperText>

        <TextField size="small" margin="normal" variant="outlined" fullWidth
                   error={errors.name!=null} label={t('landing:demoRequest.form.name')}
                   onChange={update('name')} />

        <TextField size="small" margin="normal" variant="outlined" fullWidth
                   error={errors.email!=null}
                   label={t('landing:demoRequest.form.email')}
                   onChange={update('email')} />

        <TextField size="small" margin="normal" variant="outlined" fullWidth
                   error={errors.organization!=null}
                   label={t('landing:demoRequest.form.organization')}
                   onChange={update('organization')} />

        <TextField size="small" margin="normal" multiline={true} rows={3} variant="outlined" fullWidth
                   error={errors.message!=null}
                   label={t('landing:demoRequest.form.message')}
                   onChange={update('message')} />

        <Stack direction="row">{
          success
            ? <Typography variant="body1"
                          color="primary"><Trans i18nKey="landing:demoRequest.form.success" /></Typography>
            :<Button variant="contained" onClick={submitApplication}
                     color="primary"><Trans i18nKey="landing:demoRequest.form.submit" /></Button>


        }
        </Stack>


      </ApplicationForm>
    </Stack>
  </Section>
}

const ApplicationForm = styled('form')(({ theme }) => ({
  padding: theme.spacing(4)
}))

function isValidEmail(email: string): boolean {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
}

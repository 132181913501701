import { useFirestore } from '@goschool/react-firebase'
import { doc } from 'firebase/firestore'
import { courseDocumentConverter } from '@goschool/model'
import { useMemo } from 'react'


export interface CourseDocumentParams {
  organizationId: string;
  courseId: string;
  documentId: string;
}

export function useCourseDocumentRef({ organizationId, courseId, documentId }: Readonly<Partial<CourseDocumentParams>>) {
  const firestore = useFirestore()

  return useMemo(
    () => {
      if (organizationId!=null && courseId!=null && documentId!=null) {
        return doc(firestore,
          'organizations', organizationId,
          'courses', courseId,
          'documents', documentId
        ).withConverter(courseDocumentConverter)
      }
    }, [organizationId, courseId, documentId, firestore]
  )
}



import { Authorize } from '@goschool/auth'
import { useCourseContext } from '@goschool/dao'
import { GoSchool } from '@goschool/routing'
import type { SvgIconComponent } from '@mui/icons-material'
import ChatIcon from '@mui/icons-material/Chat'
import EditIcon from '@mui/icons-material/Edit'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import QrCodeIcon from '@mui/icons-material/QrCode'
import ShareIcon from '@mui/icons-material/Share'
import { Fab, type FabProps, Grid2 as Grid, Stack } from '@mui/material'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { LinkInvitationDialog, QRInvitationDialog, useCourseInvitationGenerator } from '../components/CourseInvitation'
import { EditCourseDialog } from '../components/EditCourseDialog'
import { CourseActionLink, CourseDescription, InstructorActionLink } from './components'
import type { Course } from '@goschool/model'

export function InstructorCourse({ snapshot }: { snapshot: QueryDocumentSnapshot<Course> }) {
  const [course, ref] = useMemo(
    () => [snapshot===null ? null:snapshot?.data(), snapshot?.ref],
    [snapshot]
  )

  const { t } = useTranslation()
  return <Grid container>
    <Grid size={{ xs: 12, md: 11 }}>
      <Stack>
        <Stack direction="row" gap={2} justifyContent="stretch">
          <Stack direction="row" gap={1} justifyContent="flex-start" flexGrow={1}>
            <InstructorActionLink variant="contained" label={t('course:actions.chat')}
                                  to={GoSchool.courseChat(snapshot.ref)}
                                  Icon={ChatIcon}></InstructorActionLink>
            <InstructorActionLink variant="outlined" label={t('course:actions.studentChats')}
                                  to={GoSchool.courseChats(ref)}
                                  Icon={ForumOutlinedIcon} />
          </Stack>

          <Stack direction="row" gap={1} justifyContent="flex-end" flexGrow={1}>
            <CourseActionLink variant="outlined" label={t('course:actions.documents')}
                              to={GoSchool.courseDocuments(ref)}
                              Icon={MenuBookIcon} />
            <InstructorActionLink variant="outlined" label={t('course:actions.students')}
                                  to={GoSchool.courseStudents(ref)}
                                  Icon={GroupsIcon} />
          </Stack>
        </Stack>
        <CourseDescription>{course?.description}</CourseDescription>
      </Stack>
    </Grid>
    <Grid size={{ xs: 12, md: 1 }}>
      <InstructorActions />
    </Grid>
  </Grid>
}


function InstructorActions() {
  const { courseRoles } = useCourseContext()
  return <Stack direction="column" gap={1} alignItems="flex-end">
    <Authorize roles={['instructor', 'admin']} contextRoles={courseRoles}>
      <EditCourse />

      <InviteStudents />
      <InviteQR />

    </Authorize>
  </Stack>
}


export function InviteStudents() {
  const { invitation, isCreating, reset, createInvitation } = useCourseInvitationGenerator(72)

  return <InstructorActionButton Icon={ShareIcon} onClick={createInvitation} disabled={isCreating}>
    <LinkInvitationDialog invitation={invitation} isCreating={isCreating} hide={reset} />
  </InstructorActionButton>
}


export function InviteQR() {
  const { invitation, isCreating, reset, createInvitation } = useCourseInvitationGenerator(72)

  return <InstructorActionButton Icon={QrCodeIcon} onClick={createInvitation} disabled={isCreating}>
    <QRInvitationDialog invitation={invitation} isCreating={isCreating} hide={reset} />
  </InstructorActionButton>
}

export function EditCourse() {
  const [editing, setEditing] = useState(false)
  const edit = useCallback(() => setEditing(true), [setEditing])
  const reset = useCallback(() => setEditing(false), [setEditing])

  return <>
    <InstructorActionButton Icon={EditIcon} onClick={edit} disabled={editing} />
    <EditCourseDialog displayed={editing} hide={reset} />

  </>
}

interface InstructorActionButtonProps extends Omit<FabProps, 'size'> {
  Icon: SvgIconComponent;
}

function InstructorActionButton({ Icon, children, ...buttonProps }: InstructorActionButtonProps) {
  const { courseRoles } = useCourseContext()
  return <Authorize roles={['instructor', 'admin']} contextRoles={courseRoles}>
    <Fab color="primary" size="small" component={RouterLink}  {...buttonProps}>
      <Icon sx={{ fontSize: '16px' }} />
    </Fab>
    {children}
  </Authorize>
}

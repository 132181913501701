import { Box, Stack, Typography } from '@mui/material'
import { Breadcrumb, FixedFab } from '@goschool/components'
import { LoadingPage, PageLayout } from '@goschool/components'
import { Trans } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { CoursesGrid } from './components/CoursesGrid'
import { useMemo } from 'react'
import { useState } from 'react'
import { useUserContext } from '@goschool/auth'
import { CreateCourseDialog } from './components/CreateCourseDialog'

import { GoSchool } from '@goschool/routing'
import { typeConverter, useFirestore, useFirestoreResults } from '@goschool/react-firebase'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import { collection, orderBy, query } from 'firebase/firestore'
import type { Course, Organization } from '@goschool/model'

export function AdminBreadcrumb() {
  return <Breadcrumb to={GoSchool.admin}>
    <Trans i18nKey="course:catalog.title" />
  </Breadcrumb>
}

export function AdminPage() {
  const { roles, user, goSchoolUser, state } = useUserContext()
  const [creating, setCreating] = useState(false)

  const firestore = useFirestore()

  const organizationsRef = useMemo(
    () => query(
      collection(firestore, 'organizations').withConverter(typeConverter<Organization>()),
      orderBy('name', 'asc')
    ),
    [firestore]
  )

  const organizations = useFirestoreResults(organizationsRef)

  if (user===undefined || state==='pending' || goSchoolUser===undefined) {
    return <LoadingPage />
  }

  return <PageLayout fullScreen={false} breadcrumbs={[]}>
    <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="course:catalog.title" /></Typography>

    <Stack direction="column" gap={4}>
      {organizations?.map(org => <OrganizationAdmin key={org.id} snapshot={org} />)}
    </Stack>
    {roles.includes('instructor') &&
      <FixedFab onClick={() => setCreating(true)} color="primary" horizontal="right" vertical="bottom">
        <AddIcon />
      </FixedFab>
    }
    <CreateCourseDialog displayed={creating} hide={() => setCreating(false)} />
  </PageLayout>
}

function OrganizationAdmin({ snapshot }: { snapshot: QueryDocumentSnapshot<Organization> }) {
  const organization = snapshot.data()

  const coursesRef = useMemo(
    () => query(
      collection(snapshot.ref, 'courses').withConverter(typeConverter<Course>()),
      orderBy('title', 'asc')
    ),
    [snapshot]
  )

  const courses = useFirestoreResults(coursesRef)


  return <Box>
    <Typography variant="h5" component="h2">{organization.name}</Typography>
    <CoursesGrid courseRefs={(courses ?? []).map(c => c.ref)} />
  </Box>
}


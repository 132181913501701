import type { PropsWithChildren } from 'react'
import {
  AppBar,
  Box,
  Container, Link, Stack,
  styled,
  Toolbar, Typography
} from '@mui/material'
import { AuthButton } from '@goschool/auth'
import { GoSchool } from '@goschool/routing'
import { Breadcrumbs, Breadcrumb, HomeBreadcrumb } from './Breadcrumbs'
import { Trans } from 'react-i18next'
import { useCustomGoSchool } from './CustomGoSchoolContext'


export interface PageReference {
  title: string;
  href: string;
}

interface RootProps {
  fullScreen?: boolean;
  centered?: boolean;
  breadcrumbs?: PageReference[];
}

export function PageBreadcrumbs({ breadcrumbs }: { breadcrumbs: PageReference[] }) {
  return <Breadcrumbs separator="›">
    <HomeBreadcrumb />
    {breadcrumbs.map(
      ({ title, href }, index) => <Breadcrumb key={index} to={href}>{title}</Breadcrumb>
    )}
  </Breadcrumbs>
}

export function PageLayout({ children, fullScreen, breadcrumbs, centered }: PropsWithChildren<RootProps>) {
  return <PageContainer
    maxWidth={false} disableGutters={true} fullScreen={fullScreen} centered={centered}>
    {
      breadcrumbs &&
      <GoSchoolAppBar position="static" elevation={0}>
        <Toolbar variant="dense" disableGutters={true}>
          <PageBreadcrumbs breadcrumbs={breadcrumbs ?? []} />
        </Toolbar>
        <AuthButton />
      </GoSchoolAppBar>
    }
    {
      fullScreen
        ? children
        :<ContentContainer>{children}</ContentContainer>
    }
    {
      !fullScreen && <Footer />
    }
  </PageContainer>
}

function Footer() {
  const { secondaryLogo } = useCustomGoSchool()
  return <FooterContainer>
    <Container maxWidth="lg">
      <Stack direction="row" justifyContent="space-between" alignItems="stretch">
        <Stack gap={1}>
          <img src={secondaryLogo} alt="GoSchool.AI" style={{ height: '30px', maxHeight: '30px', width: 'auto' }} />
        </Stack>
        <Stack justifyContent="center">
          <Typography variant="body2">&copy; 2024 GoSchool.AI</Typography>
        </Stack>
        <Stack gap={0}>
          <FooterLink href={GoSchool.termsOfUse}>
            <Trans i18nKey="legal:termsOfUse" />
          </FooterLink>
          <FooterLink href={GoSchool.privacyStatement}>
            <Trans i18nKey="legal:privacyPolicy" />
          </FooterLink>
        </Stack>
      </Stack>
    </Container>
  </FooterContainer>
}

const FooterLink = styled(Link, {
  name: 'FooterLink',
  slot: 'Root'
})(
  ({ theme }) => ({
    textDecoration: 'none',
    ...theme.typography.caption
  })
)


const FooterContainer = styled(Box, {
  name: 'FooterContainer',
  slot: 'Root'
})(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    '& > MuiContainer-root': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
)


export function ContentContainer({ children }: PropsWithChildren) {
  return <ContentContainerStyle maxWidth="lg">{children}</ContentContainerStyle>
}

export const GoSchoolAppBar = styled(AppBar)(
  ({ theme }) => ({
    flex: '0 0 auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  })
)

const PageContainer = styled(Container, {
  name: 'ContentContainer',
  slot: 'Root',
  shouldForwardProp: (prop) => prop!=='fullScreen' && prop!=='centered'
})<RootProps>(
  ({ theme, fullScreen, centered }) => {
    const commonStyles = {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    } as const

    if (fullScreen) {
      return {
        ...commonStyles,
        overflow: 'hidden',
        height: '100vh',
        justifyContent: centered ? 'center':'stretch'
      }
    } else {
      return {
        ...commonStyles,
        overflowX: 'hidden',
        overflowY: 'auto',
        justifyContent: 'stretch',
        minHeight: '100vh'
      }
    }
  }
)


const ContentContainerStyle = styled(Container, {
  name: 'ContentContainer',
  slot: 'Root'
})(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    gap: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  })
)

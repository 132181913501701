import type { Analytics } from 'firebase/analytics'
import { getAnalytics, setUserId } from 'firebase/analytics'
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { useFirebaseApp } from './FirebaseAppContext'
import { useFirebaseAuth } from './FirebaseAuthContext'

interface FirebaseAnalyticsContextType {
  analytics: Analytics;
}

export const FirebaseAnalyticsContext = createContext<
  FirebaseAnalyticsContextType | undefined
>(undefined)

export function FirebaseAnalyticsContextProvider({
  children,
}: PropsWithChildren) {
  const app = useFirebaseApp()
  const analytics = useMemo(() => getAnalytics(app), [app])
  const { user } = useFirebaseAuth()
  useEffect(() => {
    if (user) {
      setUserId(analytics, user.uid)
    }
  }, [analytics, user])
  return (
    <FirebaseAnalyticsContext.Provider value={{ analytics }}>
      {children}
    </FirebaseAnalyticsContext.Provider>
  )
}

export function useFirebaseAnalytics() {
  const context = useContext(FirebaseAnalyticsContext)
  if (!context) {
    throw new Error(
      'useFirebaseAnalytics must be used within a FirebaseAnalyticsContextProvider'
    )
  }
  return context
}

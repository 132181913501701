import { HttpError } from './HttpError'

export class PermissionDenied extends HttpError {
  constructor(cause?: Error, context?: unknown) {
    super(
      'Not authorized',
      'You do not have permission to access the requested resource. This may be due to insufficient privileges to view this content or perform this action. If you believe this is a mistake, please verify your account permissions or contact an administrator. If you were trying to access a specific resource, ensure you have the correct URL and the necessary permissions.',
      403,
      { cause },
      context
    )
  }
}

export class Unauthorized extends HttpError {
  constructor(cause?: Error, context?: unknown) {
    super(
      'Not authenticated',
      'You\'re attempting to access a resource that requires authentication. Please log in with your credentials to proceed. If you believe you\'re seeing this message in error, ensure that you\'re logged in with an account that has access to this resource. If the issue persists, please contact support for assistance.',
      401,
      { cause },
      context
    )
  }
}

export class Forbidden extends HttpError {
  constructor(cause?: Error, context?: unknown) {
    super(
      'Not authorized',
      'You do not have permission to access the requested resource. This may be due to insufficient privileges to view this content or perform this action. If you believe this is a mistake, please verify your account permissions or contact an administrator. If you were trying to access a specific resource, ensure you have the correct URL and the necessary permissions.',
      403,
      { cause },
      context
    )
  }
}

export class NotFound extends HttpError {
  constructor(cause?: Error, context?: unknown) {
    super(
      'Not found',
      'The resource you\'re trying to reach cannot be found on the server. This could be due to an incorrect URL or the resource has been moved or deleted. Please check the URL for any typos and try again. Alternatively, you can return to the homepage or use the search feature to find the information you\'re looking for. If you continue to encounter this error, the content may no longer exist.',
      404,
      { cause },
      context
    )
  }
}

export class InternalError extends HttpError {
  constructor(cause?: Error, context?: unknown) {
    super(
      'Internal error',
      'We encountered an unexpected problem while processing your request. This is a general error indicating a problem on our server, not with your computer or Internet connection. Please try refreshing the page or coming back later. We apologize for the inconvenience and are working to resolve the issue as quickly as possible. If the problem persists, please contact support with a description of the action you were performing when this error occurred.',
      500,
      { cause },
      context
    )
  }
}

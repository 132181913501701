import { Box, styled } from '@mui/material'
import type { ComponentProps } from 'react'
import ReactMarkdown from 'react-markdown'

export function CourseDescription({ children }: ComponentProps<typeof ReactMarkdown>) {
  return <Box>
    <StyledMarkdown
    >{children}</StyledMarkdown>
  </Box>
}

const StyledMarkdown = styled(ReactMarkdown)(({ theme }) => ({
  ...theme.typography.body2,
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  '& table': {
    borderCollapse: 'collapse',
    '& td, & th': {
      border: `1px solid ${theme.palette.divider}`
    }
  },
  '.katex .katex-mathml': {
    display: 'none'
  }
}))

import { useFirebaseApp } from '@goschool/react-firebase'
import type { User } from 'firebase/auth'
import type { DocumentReference} from 'firebase/firestore'
import { serverTimestamp, setDoc } from 'firebase/firestore'
import { ChatManager } from '@goschool/shared/chat'
import { useCallback, useEffect, useState } from 'react'
import { type Chat, type CourseChat, GoSchoolFunctions } from '@goschool/model'

export function useChatManager(user: User | null | undefined, selectedChat: DocumentReference<Chat> | undefined) {

  const app = useFirebaseApp()
  const initChat = useCallback(
    async (chatRef: DocumentReference<CourseChat>) => {
      await setDoc(
        chatRef, {
          created_at: serverTimestamp(), updated_at: serverTimestamp(),
          title: null,
          created_by: user?.uid ?? null,
          participants: user!=null ? [user.uid]:[],
          submit_state: 'idle'
        }
      )
    },
    [user]
  )

  const [chatManager, setChatmanager] = useState<ChatManager>()
  useEffect(() => {
    if (selectedChat!==undefined && user!==undefined) {
      const cloudFunctions = new GoSchoolFunctions(app, 'europe-west1')
      const manager = new ChatManager(selectedChat, user, initChat, cloudFunctions)
      setChatmanager(manager)

      return () => {
        manager.dispose()
      }
    }
  }, [app, initChat, selectedChat, user])

  return chatManager
}

import { Box, Skeleton, Typography } from '@mui/material'
import { LoadingPage, PageLayout } from '@goschool/components'
import { useParams } from 'react-router-dom'
import {
  NotFound, typeConverter, useFirestore, useFirestoreResults, useFirestoreSnapshot
} from '@goschool/react-firebase'
import { CourseContext, CourseContextProvider, useCourseRef } from '@goschool/dao'
import { useMemo } from 'react'
import { GoSchool } from '@goschool/routing'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import type { Course, GoSchoolUser } from '@goschool/model'
import { InstructorCourse, StudentCourse } from './course'
import { query, collection, where, orderBy, limit, documentId } from 'firebase/firestore'

export function coursePageRef(courseSnapshot: QueryDocumentSnapshot<Course>) {
  return {
    title: courseSnapshot.data().title,
    href: GoSchool.course(courseSnapshot.ref)
  }
}

export function CoursePage() {
  const courseRef = useCourseRef(useParams<{
    organizationId: string
    courseId: string
  }>())

  const courseSnapshot = useFirestoreSnapshot(courseRef)
  const course = useMemo(
    () => courseSnapshot===null ? null:courseSnapshot?.data(),
    [courseSnapshot]
  )

  const instructors = useCourseInstructors(course)
  const instructorNames = useMemo(
    () => {
      return instructors?.map(i => i.data().name).join(', ')
    },
    [instructors]
  )

  if (courseSnapshot==null) {
    return LoadingPage()
  }

  if (!courseSnapshot.exists()) {
    throw new NotFound()
  }

  if (course===null) {
    throw new NotFound()
  }

  return <PageLayout fullScreen={false} breadcrumbs={[coursePageRef(courseSnapshot)]}>
    <CourseContextProvider courseSnapshot={courseSnapshot}>
      <Box>
        <Typography variant="h4" gutterBottom={false} component="h1">{course?.title ?? <Skeleton />}&nbsp;
          <Typography variant="caption">{course?.code ?? <Skeleton />}</Typography></Typography>
        <Typography variant="subtitle1">{instructorNames}</Typography>
      </Box>
      <CourseContext.Consumer>{
        (context) => {
          if (context==null) {
            throw new Error('CourseContext is null')
          }
          const { courseRoles } = context
          if ((courseRoles.includes('instructor') || courseRoles.includes('admin'))) {
            return <InstructorCourse snapshot={courseSnapshot} />
          } else if (courseRoles.includes('student')) {
            return <StudentCourse snapshot={courseSnapshot} />
          } else {
            return null
          }
        }
      }</CourseContext.Consumer>
    </CourseContextProvider>
  </PageLayout>
}


function useCourseInstructors(course?: Course | null) {
  const firestore = useFirestore()

  const instructorsRef = useMemo(
    () => {
      if (course==null) {
        return
      }
      const instructors = [...course.instructors ?? [], course.owner]
      if (instructors.length===0) {
        return
      }
      return query(
        collection(firestore, 'users').withConverter(typeConverter<GoSchoolUser>()),
        where(documentId(), 'in', instructors),
        orderBy('name', 'asc'),
        limit(10)
      )
    },
    [course, firestore]
  )

  return useFirestoreResults(instructorsRef)
}

import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { LoadingPage, PageLayout } from '@goschool/components'
import { Trans, useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useUserContext } from '@goschool/auth'

import { typeConverter, useFirestore, useFirestoreResults } from '@goschool/react-firebase'
import { collectionGroup, type QueryDocumentSnapshot } from 'firebase/firestore'
import type { Organization } from '@goschool/model'
import { GoSchool } from '@goschool/routing'

export function OrganizationsPage() {
  const { roles, user, goSchoolUser, state } = useUserContext()

  const firestore = useFirestore()
  const organizationsRef = useMemo(
    () => collectionGroup(firestore, 'organizations').withConverter(typeConverter<Organization>()),
    [firestore]
  )

  const organizations = useFirestoreResults(organizationsRef)
  const { t } = useTranslation()

  if (user===undefined || state==='pending' || goSchoolUser===undefined) {
    return <LoadingPage />
  }


  return <PageLayout fullScreen={false}>
    <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="admin:organizations.title" /></Typography>

    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('admin:organizations.tableHeaders.name')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(organizations ?? []).map((org) => <OrganizationRow key={org.id} snapshot={org} />)}
        </TableBody>
      </Table>
    </TableContainer>
  </PageLayout>
}


function OrganizationRow({ snapshot }: { snapshot: QueryDocumentSnapshot<Organization> }) {
  const organization = useMemo(() => snapshot.data(), [snapshot])


  return <TableRow>
    <TableCell>
      <Link
        href={`${window.location.origin}${GoSchool.adminOrganization(snapshot.ref)}`}>{organization.name}</Link>
    </TableCell>
  </TableRow>
}


import type { DocumentReference, DocumentSnapshot , Unsubscribe } from 'firebase/firestore'
import { getDoc, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { EventEmitter } from '../util'
import {
  InternalError,
  PermissionDenied,

} from '../errors'
import { FirebaseError } from 'firebase/app'
import { useFirebaseAuth } from '../FirebaseAuthContext'

export interface FirestoreResultsOptions {
  watch?: boolean;
  waitForWrites?: boolean;
}

export const defaultOptions = {
  watch: true,
  waitForWrites: false,
}

type ResponseType<T> = [
  snapshot: DocumentSnapshot<T> | null | undefined,
  error: Error | null | undefined
];

export function useFirestoreSnapshot<T>(
  ref: DocumentReference<T> | undefined | null,
  options?: FirestoreResultsOptions
): DocumentSnapshot<T> | null | undefined {
  const [result, error] = useFirestoreSnapshotWithError(ref, options)
  if (error) {
    throw error
  }
  return result
}

export function useFirestoreSnapshotWithError<T>(
  ref: DocumentReference<T> | undefined | null,
  options?: FirestoreResultsOptions
): ResponseType<T> {
  const [result, setResult] = useState<DocumentSnapshot<T> | null>()
  const [error, setError] = useState<Error | null>()
  const { user } = useFirebaseAuth()

  useEffect(() => {
    const manager = new FirestoreSnapshotManager(ref, {
      waitForWrites: options?.waitForWrites ?? defaultOptions.waitForWrites,
      watch: options?.watch ?? defaultOptions.watch,
    })

    const handleUpdate = (snapshot: DocumentSnapshot<T>) => {
      setResult(snapshot)
      setError(null)
    }

    const handleFailure = (error: Error) => {
      setError(error)
      setResult(undefined)
    }

    manager.addEventListener('updated', handleUpdate)
    manager.addEventListener('failed', handleFailure)

    return () => {
      manager.removeEventListener('updated', handleUpdate)
      manager.removeEventListener('failed', handleFailure)
      manager.close()
    }
  }, [ref, user, options?.watch, options?.waitForWrites])

  return [result, error]
}

interface FirestoreSnapshotEvents<T> {
  subscribed: undefined;
  closed: undefined;
  fetching: undefined;
  updated: DocumentSnapshot<T>;
  failed: Error;
}

export class FirestoreSnapshotManager<T> extends EventEmitter<
  FirestoreSnapshotEvents<T>
> {
  private _snapshot: DocumentSnapshot<T> | null | undefined
  private _error: Error | null | undefined
  private _unsubscribe: Unsubscribe | undefined
  private _closed = false

  public get snapshot(): DocumentSnapshot<T> | null | undefined {
    return this._snapshot
  }

  public get error(): Error | null | undefined {
    return this._error
  }

  public get state() {
    if (this.ref === undefined) {
      return 'undefined'
    } else if (this.ref === null) {
      return 'null'
    } else if (this._closed) {
      return 'closed'
    } else if (this._snapshot === undefined) {
      return 'loading'
    } else if (this._snapshot === null) {
      return 'not-found'
    } else if (this._error) {
      return 'failed'
    } else {
      throw new Error('Invalid state')
    }
  }

  constructor(
    private ref: DocumentReference<T> | undefined | null,
    private options?: FirestoreResultsOptions
  ) {
    super()

    if (ref === null) {
      this._snapshot = null
      this._error = null
    } else if (ref != null) {
      if (options?.watch ?? defaultOptions.watch) {
        this.subscribe()
        this.emit('subscribed', undefined)
      } else {
        this.emit('fetching', undefined)
        this.fetch()
      }
    }
  }

  private async fetch() {
    if (this.ref === undefined) {
      this._snapshot = undefined
      this._error = null
    } else if (this.ref === null) {
      this._snapshot = null
      this._error = null
    } else {
      try {
        this.emit('fetching', undefined)
        this._snapshot = await getDoc(this.ref)
        this.emit('updated', this._snapshot)
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  close() {
    if (this._unsubscribe) {
      this._unsubscribe()
      this._unsubscribe = undefined
      this._closed = true
      this.emit('closed', undefined)
    }
  }

  private subscribe() {
    if (this.ref == null) {
      throw new Error('ref is null')
    }
    const waitForWrites =
      this.options?.waitForWrites ?? defaultOptions.waitForWrites
    try {
      this._unsubscribe = onSnapshot(
        this.ref,
        { includeMetadataChanges: true },
        (snapshot) => {
          if (!waitForWrites || !snapshot.metadata.hasPendingWrites) {
            this._snapshot = snapshot
            this.emit('updated', snapshot)
          }
        },
        this.handleError
      )
    } catch (error) {
      this.handleError(error)
    }
  }

  private handleError = (error: unknown) => {
    this._snapshot = undefined
    if (error instanceof FirebaseError) {
      if (error.code === 'permission-denied') {
        this._error = new PermissionDenied(error)
      } else {
        this._error = new InternalError(error)
      }
    } else {
      this._error = new InternalError()
    }
    this.emit('failed', this._error)
  }
}

import type {
  DocumentData,
  DocumentReference,
  Firestore,
  FirestoreDataConverter,
} from 'firebase/firestore'
import { doc } from 'firebase/firestore'
import type {
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  WithFieldValue,
} from '@firebase/firestore'

export function typeConverter<
  T,
  D extends DocumentData = DocumentData
>(): FirestoreDataConverter<T, D> {
  return {
    fromFirestore: fromFirestore,
    toFirestore: toFirestore,
  }
}

function fromFirestore<T>(
  snapshot: QueryDocumentSnapshot,
  options?: SnapshotOptions
): T {
  return snapshot.data(options) as T
}

function toFirestore<T, D extends DocumentData>(
  modelObject: WithFieldValue<T>
): WithFieldValue<D>;
function toFirestore<T, D extends DocumentData>(
  modelObject: PartialWithFieldValue<T>,
  options: SetOptions
): PartialWithFieldValue<D>;
function toFirestore<T, D extends DocumentData>(
  modelObject: WithFieldValue<T> | PartialWithFieldValue<T>,
  options?: SetOptions
): WithFieldValue<D> | PartialWithFieldValue<D> {
  if (options) {
    return modelObject as PartialWithFieldValue<D>
  } else {
    return modelObject as WithFieldValue<D>
  }
}

export function typedReference<T>(
  firestore: Firestore,
  path: string,
  ...pathSegments: string[]
): DocumentReference<T> {
  return doc(firestore, path, ...pathSegments).withConverter(
    typeConverter<T>()
  )
}

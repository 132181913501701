import { useFirebaseApp } from '@goschool/react-firebase'
import type { FirebaseApp } from 'firebase/app'
import type { DocumentReference } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useMemo } from 'react'
import type { Chat, ChatMessage } from './Chat'
import type { CourseDocument } from './CourseDocument'
import type { GoSchoolInvitation } from './GoSchoolInvitation'

export function useGoSchoolFunctions() {
  const app = useFirebaseApp()
  return useMemo(
    () => new GoSchoolFunctions(app, 'europe-west1'),
    [app]
  )
}


export class GoSchoolCloudFunctions {
  constructor(private readonly app: FirebaseApp, private readonly regionOrCustomDomain = 'europe-west1') {
  }

  private getFunction<T, R>(name: string) {
    const functions = getFunctions(this.app, this.regionOrCustomDomain)
    return httpsCallable<T, R>(functions, name)
  }

  readonly accept_invitation = this.getFunction<
    { invitation_id: string },
    { success: boolean }>('accept_invitation')

  readonly delete_document = this.getFunction<
    { path: string },
    { success: boolean }>('delete_document')


  readonly chat_action = this.getFunction<
    ChatAction,
    { ok: boolean }>('chat_action')
}


interface SendPromptAction {
  action: 'send_prompt'
  chat_path: string
  prompt: string
  prev_message_id?: string | null
}

interface RegenerateAgentMessageAction {
  action: 'regenerate_agent_message'
  chat_path: string
  agent_message_id: string
}

interface SaveFeedbackAction {
  action: 'save_feedback'
  chat_path: string
  agent_message_id: string
  liked?: boolean | null
  comment?: string | null
}


type ChatAction = SendPromptAction | RegenerateAgentMessageAction | SaveFeedbackAction


export class GoSchoolFunctions extends GoSchoolCloudFunctions {
  constructor(app: FirebaseApp, regionOrCustomDomain = 'europe-west1') {
    super(app, regionOrCustomDomain)
  }

  readonly acceptInvitation = async (ref: DocumentReference<GoSchoolInvitation>) => {
    const result = await this.accept_invitation({
      invitation_id: ref.id
    })
    return result.data
  }

  readonly deleteDocument = async (ref: DocumentReference<CourseDocument>) => {
    const result = await this.delete_document({
      path: ref.path
    })
    return result.data
  }

  readonly retryResponse = async (ref: DocumentReference<ChatMessage>) => {
    const chatPath = ref.parent.parent?.path
    if (chatPath==null) {
      throw new Error('Chat path not found')
    }
    const result = await this.chat_action({
      action: 'regenerate_agent_message',
      chat_path: chatPath,
      agent_message_id: ref.id
    })
    return result.data
  }

  readonly saveFeedback = async (ref: DocumentReference<ChatMessage>, liked?: boolean | null, comment?: string | null) => {
    const chatPath = ref.parent.parent?.path
    if (chatPath==null) {
      throw new Error('Chat path not found')
    }
    const result = await this.chat_action({
      action: 'save_feedback',
      chat_path: chatPath,
      agent_message_id: ref.id,
      liked: liked,
      comment: comment ?? null
    })
    return result.data
  }

  readonly sendPrompt = async (chatReference: DocumentReference<Chat>, prompt: string, prevMessageId?: string | null) => {
    const result = await this.chat_action({
      action: 'send_prompt',
      chat_path: chatReference.path,
      prompt,
      prev_message_id: prevMessageId ?? null
    })
    return result.data
  }
}

import type { Auth, User } from 'firebase/auth'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useFirebaseApp } from './FirebaseAppContext'

export interface FirebaseAuthContextType {
  auth: Auth;
  user?: User | null;
  state: 'pending' | 'success' | 'error';
}

export const FirebaseAuthContext = createContext<
  FirebaseAuthContextType | undefined
>(undefined)

export function FirebaseAuthContextProvider({ children }: PropsWithChildren) {
  const app = useFirebaseApp()
  const auth = useMemo(() => getAuth(app), [app])

  const [userState, setUserState] = useState<FirebaseAuthContextType>({
    auth,
    state: 'pending',
  })

  useEffect(() => {
    auth.authStateReady().then(() => {
      setUserState({ auth, user: auth.currentUser, state: 'success' })
      onAuthStateChanged(auth, (user) => {
        setUserState({ auth, user, state: 'success' })
      })
    })
  }, [auth, setUserState])

  return (
    <FirebaseAuthContext.Provider value={userState}>
      {children}
    </FirebaseAuthContext.Provider>
  )
}

export function useFirebaseAuth() {
  const context = useContext(FirebaseAuthContext)
  if (!context) {
    throw new Error(
      'useFirebaseAuth must be used within a FirebaseAuthContextProvider'
    )
  }
  return context
}

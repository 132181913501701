import { useAuthenticationContext } from '@goschool/auth'
import { useLayoutEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Routing } from '@goschool/routing'
import { InvitationPage, InvitationsPage } from '@goschool/invite'
import {
  AdminPage,
  CourseDocumentsPage,
  CoursePage,
  CoursesPage,
  CourseStudentsPage,
  OrganizationsPage
} from '@goschool/courses'
import { CourseDocumentPage } from '@goschool/courses'
import { CourseChatPage, CourseChatsPage, CourseChatViewPage } from '@goschool/chats'
import { TermsOfUse, PrivacyStatement } from './legal'
import { SentryReportingErrorBoundary } from './SentryReportingErrorBoundary'
import { AllChatsPage } from '@goschool/chats'
import { useFirebaseAuth } from '@goschool/react-firebase'

export interface GoSchoolRoutesProps{
  App: React.ComponentType,
  NotFound: React.ComponentType
}

export function GoSchoolRoutes({App, NotFound}: GoSchoolRoutesProps) {
  return <SentryReportingErrorBoundary>
    <Routes>
      <Route index={true} element={<App />} />
      <Route path={Routing.termsOfUse} element={<TermsOfUse />} />
      <Route path={Routing.privacyStatement} element={<PrivacyStatement />} />
      <Route path="*" element={<NotFound />} />
      <Route element={<ProtectedRoute />}>
        <Route path={Routing.admin}>
          <Route index={true} element={<AdminPage />} />
          <Route path={Routing.invitations} element={<InvitationsPage />} />
          <Route path={Routing.organizations} element={<OrganizationsPage />} />
          <Route path="chats" element={<AllChatsPage />} />
        </Route>
        <Route path={Routing.invitation} element={<InvitationPage />} />
        <Route path={Routing.courses} element={<CoursesPage />} />
        <Route path={Routing.course} element={<CoursePage />} />
        <Route path={Routing.documents} element={<CourseDocumentsPage />} />
        <Route path={Routing.document} element={<CourseDocumentPage />} />
        <Route path={Routing.courseChat} element={<CourseChatPage />} />
        <Route path={Routing.courseChatView} element={<CourseChatViewPage />} />
        <Route path={Routing.courseStudents} element={<CourseStudentsPage />} />
        <Route path={Routing.courseChats} element={<CourseChatsPage />} />
      </Route>
    </Routes>
  </SentryReportingErrorBoundary>
}


function ProtectedRoute() {
  const { user } = useFirebaseAuth()
  const { signIn } = useAuthenticationContext()
  useLayoutEffect(() => {
    if (user === null) {
      signIn()
    }
  }, [signIn, user])

  if (user == null) {
    return null
  }
  return <Outlet />
}

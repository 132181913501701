import {  styled } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import type { ComponentProps } from 'react'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import remarkGfm from 'remark-gfm'
import 'katex/dist/katex.css'

type MarkdownMessageComponentProps =
  Pick<ComponentProps<typeof ReactMarkdown>, 'children'>


export function MessageMarkdownComponent({ children }: MarkdownMessageComponentProps) {
  return <StyledMarkdown
    remarkPlugins={[remarkMath, remarkGfm]}
    rehypePlugins={[rehypeKatex]}>{children}</StyledMarkdown>
}


const StyledMarkdown = styled(ReactMarkdown)(({ theme }) => ({
  ...theme.typography.body2,
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  '& table': {
    borderCollapse: 'collapse',
    '& td, & th': {
      border: `1px solid ${theme.palette.divider}`
    }
  },
  '.katex .katex-mathml': {
    display: 'none'
  }
}))

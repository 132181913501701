export abstract class HttpError extends Error {
  protected constructor(
    public readonly title: string,
    public override message: string,
    public readonly status: number,
    options?: { cause?: unknown },
    public readonly context?: unknown
  ) {
    // @ts-expect-error super type does not expect options
    super(message, options)
  }
}

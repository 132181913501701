import type { FirebaseApp, FirebaseOptions } from 'firebase/app'
import { initializeApp } from 'firebase/app'
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useMemo } from 'react'

export interface FirebaseAppContextType {
  app: FirebaseApp;
}

export const FirebaseAppContext = createContext<
  FirebaseAppContextType | undefined
>(undefined)

export interface FirebaseAppContextProviderProps extends PropsWithChildren {
  options: FirebaseOptions;
}

export function FirebaseAppContextProvider({
  options,
  children,
}: FirebaseAppContextProviderProps) {
  const app = useMemo(() => initializeApp(options), [options])

  return (
    <FirebaseAppContext.Provider value={{ app }}>
      {children}
    </FirebaseAppContext.Provider>
  )
}

export function useFirebaseApp() {
  const firebase = useContext(FirebaseAppContext)
  if (!firebase) {
    throw new Error('useFirebase must be used within a FirebaseProvider')
  }
  return firebase.app
}

import { EventEmitter } from '../util'
import type { DocumentReference, DocumentSnapshot, Unsubscribe, QuerySnapshot } from 'firebase/firestore'
import { onSnapshot, collection } from 'firebase/firestore'
import type { Chat, ChatMessage, SubmitState } from '@goschool/model'
import type { GoSchoolFunctions } from '@goschool/model'
import { isAgentMessage } from '@goschool/model'

import type { MessageMap, MessageNode, MessageTree } from './MessageTree'
import { buildTree, collectThread, findLatestLeaf, generateMessageMap } from './MessageTree'
import type { User } from 'firebase/auth'
import { typeConverter } from '@goschool/react-firebase'
import { maxBy } from 'lodash'

interface ChatManagerEvents {
  managerStateUpdate: ManagerState
  threadUpdate: MessageThread
  submitStateUpdate: SubmitState

  messageStateUpdated: MessageNode
}


export type MessageThread = MessageNode[]
export type ManagerState = 'loading' | 'ready' | 'disposed'

export class ChatManager extends EventEmitter<ChatManagerEvents> {
  private chatSnapshot: DocumentSnapshot<Chat> | undefined
  private unsubscribeChat: Unsubscribe
  private unsubscribeMessages: Unsubscribe
  private messagesSnapshot: QuerySnapshot<ChatMessage> | undefined
  private selectedThread: MessageNode | undefined | null
  private isDisposed = false
  private messages: [map: MessageMap, tree: MessageTree] | null | undefined

  constructor(
    private readonly chatReference: DocumentReference<Chat>,
    private readonly user: User | null,
    private readonly initChat: (r: DocumentReference<Chat>) => Promise<void>,
    private readonly cloudFunctions: GoSchoolFunctions
  ) {
    super()
    this.unsubscribeChat = onSnapshot(
      this.chatReference,
      { includeMetadataChanges: true },
      this.onChatUpdate
    )
    this.unsubscribeMessages = onSnapshot(
      this.messagesCollection,
      { includeMetadataChanges: true },
      this.onMessagesUpdate
    )
  }

  get id() {
    return this.chatReference.id
  }

  get messageMap(): MessageMap | null | undefined {
    if (this.messages===undefined) {
      return undefined
    } else if (this.messages===null) {
      return null
    } else {
      return this.messages[0]
    }
  }

  get messageTree(): MessageTree | null | undefined {
    if (this.messages===undefined) {
      return undefined
    } else if (this.messages===null) {
      return null
    } else {
      return this.messages[1]
    }
  }


  get thread(): MessageThread {
    if (this.messageTree==null || this.lastMessage==null) {
      return []
    }

    return collectThread(this.lastMessage)
  }

  get lastMessage(): MessageNode | null {
    if (this.selectedThread!=null) {
      return findLatestLeaf(this.selectedThread)
    } else if (this.messageTree!=null) {
      const leafs = this.messageTree.children.map((leaf) => findLatestLeaf(leaf))
      return maxBy(leafs, (leaf) => leaf.message.data().created_at) ?? null
    } else {
      return null
    }
  }

  readonly post = async (prompt: string, parent?: string) => {
    const chatReference = this.chatReference
    if (this.chat==null) {
      await this.initChat(chatReference)
    }
    const result = await this.cloudFunctions.sendPrompt(
      chatReference,
      prompt,
      parent ?? this.lastMessage?.message.id ?? null
    )
  }

  get state(): ManagerState {
    if (this.chatSnapshot===undefined || this.messagesSnapshot===undefined) {
      return 'loading'
    }

    if (this.isDisposed) {
      return 'disposed'
    }

    return 'ready'
  }

  get submitState(): SubmitState {
    return this.chat?.submit_state ?? 'idle'
  }

  readonly selectThread = (node: MessageNode) => {
    this.selectedThread = node
    this.emit('threadUpdate', this.thread)
  }

  readonly startThread = (node: MessageNode) => {
    this.selectedThread = node
    this.emit('threadUpdate', this.thread)
  }

  readonly dispose = () => {
    this.isDisposed = true
    this.unsubscribeChat()
    this.unsubscribeMessages()
  }

  get chat(): Chat | undefined {
    return this.chatSnapshot?.data()
  }

  get messagesCollection() {
    return collection(this.chatReference, 'messages').withConverter(
      typeConverter<ChatMessage>()
    )
  }


  private readonly onChatUpdate = (snapshot: DocumentSnapshot<Chat>) => {
    if (!snapshot.metadata.hasPendingWrites) {
      const justLoaded = this.chatSnapshot===undefined
      this.chatSnapshot = snapshot
      if (justLoaded) {
        this.emit('managerStateUpdate', this.state)
      }
      this.emit('submitStateUpdate', this.submitState)
    }
  }

  private readonly onMessagesUpdate = (snapshot: QuerySnapshot<ChatMessage>) => {
    if (!snapshot.metadata.hasPendingWrites) {
      this.messagesSnapshot = snapshot
      if (this.messagesSnapshot.empty) {
        this.messages = null
      } else {
        const map = generateMessageMap(snapshot.docs)
        const tree = buildTree(map)
        const updatedAgentNodes = this.getUpdatedStateAgentMessages(map)
        this.messages = [map, tree]
        updatedAgentNodes.forEach((node) => {
          this.emit('messageStateUpdated', node)
        })
      }
      this.emit('threadUpdate', this.thread)
    }
  }

  private getUpdatedStateAgentMessages(map: MessageMap) {
    if (this.messages!=null) {
      const [oldMap] = this.messages
      const changedAgentNodes = Object
        .entries(map)
        .filter(([id, node]) => {
          const message = node.message.data()
          if (!isAgentMessage(message)) {
            return false
          }
          if (!(id in oldMap)) {
            return true
          }
          const oldMessage = oldMap[id].message.data()
          if (!isAgentMessage(oldMessage)) {
            return true
          }
          return oldMessage.status!==message.status
        })
      return changedAgentNodes.map(([_, node]) => node)
    } else {
      return []
    }
  }
}

import { collection } from 'firebase/firestore'
import { useCourseContext } from '../course'
import { useMemo } from 'react'
import { useFirestoreResults } from '@goschool/react-firebase'
import { courseDocumentConverter } from '@goschool/model'

export function useCourseDocuments() {
  const {courseSnapshot} = useCourseContext()

  const courseDocumentsCollectionRef = useMemo(
    () => {
      if (courseSnapshot!=null) {
        return collection(courseSnapshot.ref, 'documents').withConverter(courseDocumentConverter)
      }
    }, [courseSnapshot]
  )

  const courseDocuments = useFirestoreResults(courseDocumentsCollectionRef)

  return { courseDocuments, courseDocumentsCollectionRef }
}

import type { ChatManager, MessageThread } from '../model'
import type { PropsWithChildren, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { createContext, useContext } from 'react'
import type { MessageNode } from '../model/MessageTree'
import type { SubmitState } from '@goschool/model'
import { isAgentMessage } from '@goschool/model'


export type ContentComponentType =
  (props: { children: string | null | undefined }) => ReactNode


export interface ChatContextType {
  mode: 'chat' | 'view'
  ContentComponent?: ContentComponentType
  samplePrompts?: string[]
  chatManager: ChatManager
  thread: MessageThread
  generatingMessage: MessageNode | null
  submitState: SubmitState
}


export interface ChatContextProviderProps extends PropsWithChildren {
  mode: 'chat' | 'view'
  ContentComponent?: ContentComponentType
  samplePrompts?: string[]
  chatManager: ChatManager | undefined
}

const ChatContext = createContext<ChatContextType | undefined>(undefined)

export function ChatContextProvider({ children, chatManager, ...context }: ChatContextProviderProps) {

  const [thread, setThread] = useState<MessageThread>()
  const [generatingMessage, setGeneratingMessage] = useState<MessageNode | null>(null)
  const [submitState, setSubmitState] = useState<SubmitState>('idle')

  useEffect(() => {
    if (chatManager!=null) {
      const handleMessageStateUpdate = (node: MessageNode) => {
        const message = node.message.data()
        if (isAgentMessage(message)) {
          setGeneratingMessage((current) => {
            if (message.status!=='generating') {
              return null
            }
            if (current!=null) {
              console.warn('Overwriting generating message')
            }
            return node
          })
        }
      }
      const handleSubmitStateUpdate = () => {
        setSubmitState(chatManager.submitState)
      }
      chatManager.addEventListener('threadUpdate', setThread)
      chatManager.addEventListener('messageStateUpdated', handleMessageStateUpdate)
      chatManager.addEventListener('submitStateUpdate', handleSubmitStateUpdate)

      return () => {
        chatManager.removeEventListener('threadUpdate', setThread)
        chatManager.removeEventListener('messageStateUpdated', handleMessageStateUpdate)
        chatManager.removeEventListener('submitStateUpdate', handleSubmitStateUpdate)
      }
    }
  }, [chatManager])

  if (chatManager==null || thread==null) {
    return null
  }

  return <ChatContext.Provider
    value={{ ...context, chatManager, thread, generatingMessage, submitState }}>{children}</ChatContext.Provider>
}

export function useChatContext() {
  const context = useContext(ChatContext)
  if (context==null) {
    throw new Error('useChatContext must be used within a ChatContextProvider')
  }
  return context
}

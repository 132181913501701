import { createContext, useContext } from 'react'

interface ChatViewContextType {
  scrollIntoView: (ref: HTMLDivElement) => void
}

export const ChatViewContext = createContext<ChatViewContextType | undefined>(undefined)

export function ChatViewContextProvider({ children }: { children: React.ReactNode }) {
  const scrollIntoView = (ref: HTMLDivElement) => {
    ref.scrollIntoView({ behavior: 'smooth' })
  }

  return <ChatViewContext.Provider value={{ scrollIntoView }}>
    {children}
  </ChatViewContext.Provider>
}


export function useChatViewContext() {
  const context = useContext(ChatViewContext)
  if (context===undefined) {
    throw new Error('useChatViewContext must be used within a ChatViewContextProvider')
  }
  return context
}

import type { Timestamp } from 'firebase/firestore'
import type OpenAI from 'openai'

export type SubmitState =
  | 'idle'
  | 'pending'
  | 'generating'
  | 'failed'

export interface Chat {
  created_at: Timestamp
  updated_at: Timestamp
  title: string | null
  created_by: string | null
  participants: string[]
  submit_state: SubmitState
  anonymous?: boolean
}

export interface ChatMessage {
  created_at: Timestamp
  content: string
  type: 'human' | 'agent'
  parent: string | null
}

export interface UserMessage extends ChatMessage {
  type: 'human'
  user: string | null
}

export function isUserMessage(message: ChatMessage): message is UserMessage {
  return message.type==='human'
}

export interface AgentMessageFeedback {
  liked: boolean
  comment?: string | null
  timestamp: Timestamp
}


export interface AgentMessage extends ChatMessage {
  type: 'agent'
  status: 'pending' | 'generating' | 'completed' | 'failed'
  user: string | null
  tool_calls?: string[]
  messages?: OpenAI.ChatCompletionMessageParam[]
  feedback?: Record<string, AgentMessageFeedback>
}

export function isAgentMessage(message: ChatMessage): message is AgentMessage {
  return message.type==='agent'
}

export interface ChatUser {
  uid: string
  name: string
  avatar?: string
}


function isAgentMessageParam(m: OpenAI.ChatCompletionMessageParam): m is OpenAI.ChatCompletionAssistantMessageParam {
  return m.role === 'assistant'
}

function isToolMessageParam(m: OpenAI.ChatCompletionMessageParam): m is OpenAI.ChatCompletionToolMessageParam {
  return m.role === 'tool'
}

function getToolCalls(message: AgentMessage): OpenAI.ChatCompletionMessageToolCall[] {
  return message.messages?.flatMap(
    m => isAgentMessageParam(m) ? m.tool_calls ?? []:[]
  ) ?? []
}

function getToolMessages(message: AgentMessage): OpenAI.ChatCompletionToolMessageParam[] {
  return message.messages?.filter(isToolMessageParam) ?? []
}

export function getToolResults(message: AgentMessage): [call: OpenAI.ChatCompletionMessageToolCall, message: OpenAI.ChatCompletionToolMessageParam | undefined][] {
  const toolMessages: Record<string, OpenAI.ChatCompletionToolMessageParam> = Object.fromEntries(
    getToolMessages(message).map(m => [m.tool_call_id, m])
  )

  const toolCalls = getToolCalls(message)
  return toolCalls
    .map(
      call => [call, toolMessages[call.id]]
    )
}
